<template>
  <ul class="menu-nav">
    <template v-for="(menu, m) in menuItems">
      <template v-if="checkIfUserHasRole(menu.roles)">
        <template v-if="_.size(menu.section.title) > 0">
          <KTMenuSection
            :section="menu.section"
            :key="'ul_nav_' + m"
          ></KTMenuSection>
        </template>
        <template v-for="(navitem, i) in menu.items">
          <KTMenuItem :navitem="navitem" :key="m + '_' + i"></KTMenuItem>
        </template>
      </template>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/view/layout/aside/components/MenuItem.vue";
import KTMenuSection from "@/view/layout/aside/components/MenuSection.vue";
import menuConfig from "@/core/config/menu.config.json";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

import { menuMixin } from "@/core/mixins/menuMixin.js";

export default {
  name: "KTMenu",
  mixins: [permissionMixin, menuMixin],
  components: {
    KTMenuItem,
    KTMenuSection
  },
  computed: {
    menuItems: () => {
      return menuConfig.aside.menu;
    }
  },
  methods: {},
  mounted() {}
};
</script>
