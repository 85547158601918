<template>
  <router-link
    :to="link()"
    v-slot="{ href, navigate, isActive, isExactActive }"
    v-permission="navitem.permissions"
  >
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
      :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        hasActivePrefix(navitem.mainNavPrefix) && 'menu-item-active'
      ]"
    >
      <a :href="href" class="menu-link" @click="navigate">
        <i v-if="navitem.icon" class="menu-icon" :class="navitem.icon"></i>
        <i v-else class="menu-bullet" :class="bullet()"><span></span></i>

        <span class="menu-text">{{
          navitem.translate ? $t(navitem.translate) : navitem.title
        }}</span>
        <span v-if="hasBadgeKey(navitem)" class="menu-label"
          ><span class="label label-danger label-inline">{{
            badges[navitem.badgeKey]
          }}</span></span
        >
      </a>
    </li>
  </router-link>
</template>

<script>
import { menuMixin } from "@/core/mixins/menuMixin.js";
import { mapState } from "vuex";

export default {
  name: "KTMenuItemText",
  mixins: [menuMixin],
  components: {},
  props: {
    navitem: Object
  },
  computed: {
    ...mapState({
      badges: state => state.auth.badges
    })
  },
  methods: {
    hasActivePrefix(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hasBadgeKey(navitem) {
      //Първо да проверя, дали е взело от стора стейта
      //Да проверя дали и navItem-a въобще е зададен badgeKey json Key
      return (
        navitem.badgeKey &&
        this.badges.hasOwnProperty(navitem.badgeKey) &&
        this.badges[navitem.badgeKey] > 0
      );
    }
  }
};
</script>
